import React,{createRef, forwardRef,useImperativeHandle,useEffect, useState,useRef } from 'react';
import { Table,Modal} from "reactstrap";
import { createRoot } from 'react-dom/client';

import Swal from 'sweetalert2';
import API from '../../API';
import {DataTable} from '../';
import parse from 'html-react-parser'
import "react-datepicker/dist/react-datepicker.css";

const CustomerList = forwardRef((props,ref) =>{
    const [ModalState,setModalState] = useState(false);


    const _ref = useRef();
    useEffect(() => {
        (async () => {
            
        })();

        return ()=>{ }
    }, []);
    
    useImperativeHandle(ref, () => ({
        Show(){
            console.log(555);
            setModalState(true);
            _ref.current?.reset();
        },

      }));
    
    const _configs = {
        "order": [[0, "asc"]],
        pageLength: 10,
        orderMulti: false
    }
    const _ajax  = {
        'url': '/Customer/List',
        'method': 'POST',
        'caches': false,
        'global': false,
        'data': function (d) {
        }
    };
    
    const _Buttons = (d,r) => (
        <div className='btn-group btn-group-sm'>
            <button type='button' title='รายละเอียด' className='btn btn-info text-white' onClick={() => {
                _SelectCustomer(d);
            }}>เลือก</button>
        </div>
    )
    const _column = [
        { data: "CustomerCode", name: "CustomerCode", autoWidth: true },
        { data: "Name", name: "Name", autoWidth: true },
        { data: "Mobile", name: "Mobile", autoWidth: true },
        { data: "AccountType", name: "AccountType", autoWidth: true,render(data){
            switch(data){
                case 1:return 'บริษัท';
                case 2:return 'ร้าน';
            }
        } },
        {
        data: "CustomerId", name: "CustomerId", autoWidth: true,
        render: (data) => { return ''; },
            createdCell: (td, cellData,rowData) => createRoot(td).render(_Buttons(cellData,rowData))
        }
    ]
    const _ColumnDefs = [
    ]
    
    const _SelectCustomer = (CustomerID)=>{
        if(props.update != undefined){

            API({method : 'get',url:'/Customer/'+CustomerID}).then(res=>{
                if(!res.data.Status){
                    Swal.fire({
                        icon: 'error',
                        title: res.data.Message
                    });
                    return;
                }
                try{
                    props.update(res.data.Data);
                }catch(e){
                    console.log(e);
                }
            }).catch(res => {
                if(res.response.data != undefined){
                    Swal.fire({
                        icon: 'error',
                        title: res.code,
                        text: res.response.data.Message
                    });
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'การเชื่อมต่อผิดพลาด',
                        text: res.message+' ['+res.code+']',
                    });
                }
                

            });
        }

        setModalState(false);

    }
    return (
        <>
        <Modal isOpen={ModalState} className={'modal-xl'} centered={true} unmountOnClose={true}>
            <div className="modal-header">
                <h5 className="modal-title">ค้นหาข้อมูลลูกค้า</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>{setModalState(false)}}></button>
            </div>
            <div className='modal-body' style={{'minHeight':'500px'}}>
                <div className='row'>
                    <div className='col-12 table-responsive'>
                        <table className='table' id="CustomerTableView">
                           <thead>
                                <tr>
                                    <th className='text-center textcenter'>รหัสลูกค้า</th>
                                    <th className='text-center'>ลูกค้า</th>
                                    <th className='text-center textcenter'>เบอร์โทร</th>
                                    <th className='text-center'>ประเภท</th>
                                    <th className='text-center textend no-sort'>#</th>
                                </tr>
                            </thead>
                            <DataTable TableID={"CustomerTableView"} Column={_column} Configs={_configs} ref={ref} ajax={_ajax} ColumnDefs={_ColumnDefs} />
                        </table>
                    </div>
                </div>
            </div>
        </Modal>
        </>)
        
});

export default React.memo(CustomerList)