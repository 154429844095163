import axios from 'axios';
import {APIURL} from './Utils/Function';
import $ from 'jquery'
let _APIURL = '';
let _APIConfig = '';


if (localStorage.getItem("Authorization") != undefined && localStorage.getItem("Authorization").length != 0) {
    _APIConfig = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem("Authorization")
    };
    $.ajaxSetup({
        headers: { 'Authorization': 'Bearer '+localStorage.getItem("Authorization") },
        beforeSend: function(xhr, options) {
            if( options.url.indexOf('http') !== 0 ) {
                options.url = APIURL() + options.url;
            }
        }
    });

} else {
    _APIConfig = {
        'Content-Type': 'application/json'
    };
    $.ajaxSetup({
        headers: { }
    });
}

export default axios.create({
    baseURL: APIURL(),
    headers: _APIConfig
});
