import React,{createRef, forwardRef,useImperativeHandle,useEffect, useState,useRef } from 'react';
import { Form,Modal} from "reactstrap";
import Swal from 'sweetalert2';
import { Controller, useForm } from "react-hook-form";
import {QuotationInfoCard,QuotationCreate} from '../../Components/Quotation'
import {CustomerCreate,CustomerList} from '../../Components/Customer';
import API from '../../API';
import { DateFormat,DateFormatJSON, DateThai,MobileFormat, NumberFormat,CleanMobile, DateThaiFormat } from '../../Utils/Function';
import {QuotationStatusLabel,MemberLevelName} from '../../Utils/Status'
import parse from 'html-react-parser'


const PageCallCenterInfo = forwardRef((props,ref) =>{
    const { register, handleSubmit, watch, reset, getValues,setValue, control, formState: { errors } } = useForm();
    const [_ContactRef,setContactRef] = useState(null);
    const [_Customer,setCustomer] = useState(null);
    const [_QuotationList,setQuotationList] = useState([]);
    const [_NoteList,setNoteList] = useState([]);
    const [_ContactLogID,setContactLogID] = useState(null);
    const [_Note,setNote] = useState('');
    const [_CustomerID,setCustomerID] = useState(null);

    const _QuotationInfoCard = useRef();
    const _QuotationCreate = useRef();

    const _CustomerCreate = useRef();
    const _CustomerList = useRef();

    const [_SearchQuotation,setSearchQuotation] = useState(false);
    const [_SearchQuotationList,setSearchQuotationList] = useState([]);

    useEffect(() => {
        (async () => {
            if(window.location.pathname.includes('/')){
                //setMobile(window.location.pathname.split('/')[2]);
                _Load(window.location.pathname.split('/')[2]);
            }
        })();

        return ()=>{ }
    }, []);
    
    const _Load=async(ContactLogID)=>{
        if(ContactLogID === undefined) return;
        Swal.showLoading();
        setNoteList([]);
        setQuotationList([]);
        setNote('');
        setCustomer(null);
        setCustomerID(null);
        setSearchQuotation(false);
        
        API({method : "post",url:"/CallCenter/"+ContactLogID}).then(res=>{
            if (res.data.Status) {
                setContactLogID(res.data.Data.ContactLogID);
                if(res.data.Data.Customer != undefined){
                    setCustomer(res.data.Data.Customer);
                    _LoadQuotationList(res.data.Data.Customer.CustomerID);
                    setCustomerID(res.data.Data.Customer.CustomerID);
                }
                setContactRef(res.data.Data.ContactRef);
                _LoadNoteList(res.data.Data.ContactLogID);
            }
            Swal.close();

        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: 'การเชื่อมต่อผิดพลาด',
                text: res.message+' ['+res.code+']',
            });
        });
    }

    const _LoadNoteList=async(ContactLogID)=>{
        if(ContactLogID === undefined) return;

        API({method : "get",url:"/CallCenter/Contact/"+ContactLogID+"/Note?Limit=5"}).then(res=>{
            if (res.data.Status) {
                setNoteList(res.data.Data);
            }

        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: 'การเชื่อมต่อผิดพลาด',
                text: res.message+' ['+res.code+']',
            });
           
        });
    }
    const _LoadQuotationList = async(CustomerID)=>{
        API({method : "get",url:"/CallCenter/Quotation/"+CustomerID+"?Limit=5"}).then(res=>{
            if (res.data.Status) {
                setQuotationList(res.data.Data);
            }

        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: 'การเชื่อมต่อผิดพลาด',
                text: res.message+' ['+res.code+']',
            });
           
        });
    }
    const _SaveNote = async()=>{
        
        API({method : "post",url:"/CallCenter/Contact/"+_ContactLogID+"/Note",data:{Note:_Note},headers:{'content-type':'application/x-www-form-urlencoded'}}).then(res=>{
            if (!res.data.Status) return;
            _LoadNoteList(_ContactLogID);
            setNote('');
        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: 'การเชื่อมต่อผิดพลาด',
                text: res.message+' ['+res.code+']',
            });
        });
    }
    return (
        <>
        <React.Fragment>
            <div className='container-fluid'>
                <div className='row mt-4'>
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div className='card-header d-flex justify-content-between align-items-center'>
                                <h3 className='card-title'>{_ContactRef}</h3>
                                <div className='btn btn-group btn-group-lg'>
                                    <button type="button" className='btn btn-warning text-white'>ปิดงาน</button>
                                </div>
                            </div>
                            <div className='card-body' style={{'minHeight':'300px'}}>
                                <div className='row d-flex justify-content-end'>
                                    <div className='col-2 col-lg-2 mt-2 text-end'>
                                        
                                    </div>
                                    <div className='col-3 col-lg-3 text-end'>
                                        <div className='btn btn-group'>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-lg-4">
                                       <div className='row'>
                                            <div className='col-lg-12'>
                                                <div className='card'>
                                                    <div className='card-header d-flex justify-content-between align-items-center bg-primary text-white '>
                                                        <h4 className='card-title'>ข้อมูลลูกค้า</h4>
                                                        <div className='btn btn-group btn-group-sm'>
                                                            <button type="button" className='btn btn-sm btn-warning text-white' onClick={()=>{_CustomerList.current?.Show();}}>ค้นหา</button>
                                                            <button type="button" className='btn btn-sm btn-danger text-white' onClick={()=>{_CustomerCreate.current?.Show(_ContactLogID)}}>สร้าง</button>
                                                        </div>
                                                    </div>
                                                    <div className='card-body'>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className='row'>
                                                                    <div className='col-3 text-end'>
                                                                        <label className="form-label fw-bold">รหัสลูกค้า</label> 
                                                                    </div>
                                                                    <div className='col-9'>{(_Customer != null?_Customer.CustomerCode:"")}</div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-3 text-end'>
                                                                        <label className="form-label fw-bold">ชื่อลูกค้า</label> 
                                                                    </div>
                                                                    <div className='col-9'>{(_Customer != null?_Customer.Name:"")}</div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-3 text-end'>
                                                                        <label className="form-label fw-bold">เบอร์โทร</label> 
                                                                    </div>
                                                                    <div className='col-9'>{(_Customer != null?_Customer.Mobile:"")}</div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-3 text-end'>
                                                                        <label className="form-label fw-bold">ชื่อเล่น</label> 
                                                                    </div>
                                                                    <div className='col-9'>{(_Customer != null?_Customer.NickName:"")}</div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-3 text-end'>
                                                                        <label className="form-label fw-bold">ระดับลูกค้า</label> 
                                                                    </div>
                                                                    <div className='col-9'>{(_Customer != null?MemberLevelName(_Customer.MemberLevel):"")}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                       </div>
                                       <div className='row mt-2'>
                                            <div className='col-lg-12'>
                                                <div className='card'>
                                                    <div className='card-header d-flex justify-content-between align-items-center bg-info text-white '>
                                                        <h4 className='card-title'>ข้อมูลใบเสนอราคา</h4>
                                                        <div className='btn btn-group btn-group-sm'>
                                                            <button type="button" className='btn btn-sm btn-warning text-white' onClick={()=>{setSearchQuotation(true);}}>ค้นหา</button>
                                                            <button type="button" className='btn btn-sm btn-danger' disabled={_Customer == null ? true:false} onClick={()=>{ _QuotationCreate.current?.Show(_Customer);}}>สร้าง</button>
                                                        </div>
                                                    </div>
                                                    <div className='card-body'>
                                                        <div className='row'>
                                                            <div className='col-12 table-responsive'>
                                                                <table className='table table-striped'>
                                                                    <thead>
                                                                        <tr>
                                                                            <th className='text-center'>เลขที่ใบเสนอราคา</th>
                                                                            <th className='text-center'>วันที่จัดงาน</th>
                                                                            <th className='text-center'>ยอดรวม</th>
                                                                            <th className='text-center'>สถานะ</th>
                                                                            <th className='text-center'>#</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {_QuotationList.map((v,i)=>( 
                                                                            <tr key={i}>
                                                                                <td className='text-center'>{v.QuotationCode}</td>
                                                                                <td className='text-center'>
                                                                                    {v.Events.map((ev,ei)=>(
                                                                                        <div className='row' key={ei}>
                                                                                            <div className='col-12'>{DateThaiFormat(ev.EventDate)}</div>
                                                                                        </div>
                                                                                    ))}
                                                                                </td>
                                                                                <td className='text-end'>{NumberFormat(v.Profile.TotalAmount)}</td>
                                                                                <td className='text-center'>
                                                                                    {parse(QuotationStatusLabel(v.Profile.Status))}
                                                                                </td>
                                                                                <td className='text-center'>
                                                                                    <div className='btn-group btn-group-sm'>
                                                                                        <button type='button' className='btn btn-info text-white' onClick={()=>{_QuotationInfoCard.current?.Show(v.QuotationID)}}>Info</button>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                       </div>
                                    </div>
                                    <div className='col-8'>
                                        {(_SearchQuotation?(
                                            <div className='row mt-2 mb-5'>
                                                <div className='col-12'>
                                                    <div className='card'>
                                                        <div className='card-header d-flex justify-content-between align-items-center'>
                                                            <h4>ค้นหาข้อมูลใบเสนอราคา</h4>
                                                            <div className='btn btn-group btn-group-sm'>
                                                            <button type="button" className='btn btn-sm btn-danger text-white' onClick={()=>{setSearchQuotation(false);}}>ปิด</button>
                                                        </div>
                                                        </div>
                                                        <div className='card-body'>
                                                            <div className='row justify-content-center'>
                                                                <div className='col-8'>
                                                                    <div className='input-group mb-3'>
                                                                        <input type="text" className='form-control' />
                                                                        <div className='input-group-append'>
                                                                            <button className='btn btn-success' type='button'>ค้นหา</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-12 table-responsive'>
                                                                    <table className='table table-striped table-hover'>
                                                                        <thead>
                                                                            <th className='text-center'>เลขที่ใบเสนอราคา</th>
                                                                            <th className='text-center'>รหัสลูกค้า</th>
                                                                            <th className='text-center'>วันที่จัดงาน</th>
                                                                            <th className='text-center'>ยอดรวม</th>
                                                                            <th className='text-center'>สถานะ</th>
                                                                            <th className='text-center'>#</th>
                                                                        </thead>
                                                                        <tbody>
                                                                            {(_SearchQuotationList.map((v,i)=>(
                                                                                <tr key={i}>
                                                                                    <td className='text-center'>{v.QuotationCode}</td>
                                                                                    <td className='text-center'>
                                                                                        {v.Events.map((ev,ei)=>(
                                                                                            <div className='row' key={ei}>
                                                                                                <div className='col-12'>{DateThaiFormat(ev.EventDate)}</div>
                                                                                            </div>
                                                                                        ))}
                                                                                    </td>
                                                                                    <td className='text-end'>{NumberFormat(v.Profile.TotalAmount)}</td>
                                                                                    <td className='text-center'>
                                                                                        {parse(QuotationStatusLabel(v.Profile.Status))}
                                                                                    </td>
                                                                                    <td className='text-center'>
                                                                                        <div className='btn-group btn-group-sm'>
                                                                                            <button type='button' className='btn btn-info text-white' onClick={()=>{_QuotationInfoCard.current?.Show(v.QuotationID)}}>Info</button>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            )))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ):"")}

                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='card'>
                                                    <div className='card-header bg-success text-white'>
                                                        <h4 className='card-title'>ประวัติการติดต่อ</h4>
                                                    </div>
                                                    <div className='card-body'>
                                                        <section>
                                                            <div className='row'>
                                                                <div className='col-lg-12'>
                                                                    <textarea className='text form-control' style={{'minHeight':'150px'}} value={_Note} onChange={(e)=>{setNote(e.currentTarget.value);}}></textarea>
                                                                </div>
                                                            </div>
                                                            <div className='row mt-4'>
                                                                <div className='col-12 text-end'>
                                                                    <button className='btn btn-danger btn-lg' onClick={()=>{_SaveNote();}}>บันทึก</button>
                                                                </div>
                                                            </div>
                                                        </section>
                                                        <section>
                                                            {_NoteList.map((v,i)=>(
                                                                <div className='row mt-4 mb-4'>
                                                                    <div className='col-lg-12'>
                                                                        <div className='card'>
                                                                            <div className='card-header d-flex justify-content-between align-items-center '>
                                                                                <h6>{DateThaiFormat(v.CreateDate,true)}</h6>
                                                                                <div className='btn btn-group btn-group-sm'>
                                                                                    <button type="button" className='btn btn-sm btn-danger'>ลบ</button>
                                                                                </div>
                                                                            </div>
                                                                            <div className='card-body'>{v.Note}</div>
                                                                            <div className='card-footer'>
                                                                                <div className='row'>
                                                                                    <div class="col-6 text-start">
                                                                                        <span style={{fontSize:'14px'}}>{v.ChannelName}</span>
                                                                                    </div>
                                                                                    <div className='col-6 text-end'>
                                                                                        <span style={{fontSize:'14px'}}><b>บันทึกโดย</b> :: {v.CreateByName}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </section>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <QuotationInfoCard ref={_QuotationInfoCard} update={()=>{_LoadQuotationList(_CustomerID);}}/>
            <QuotationCreate ref={_QuotationCreate} update={()=>{_LoadQuotationList(_CustomerID);}}/>
            <CustomerCreate ref={_CustomerCreate} update={(Customer)=>{setCustomer(Customer);}}/>
            <CustomerList ref={_CustomerList} update={(Customer)=>{setCustomer(Customer);}}/>
                
        </React.Fragment>
        </>)
        
});

export default React.memo(PageCallCenterInfo)