import moment from 'moment';
export function NumberFormat(number){
    if(isNaN(number)) return 0;
    let nf = new Intl.NumberFormat('en-US');
    return nf.format(number);
} 
export function DateFormat(data,time=false){
    if(data === undefined || data === null) return '-';
    data = moment(data).toDate();
    if(isNaN(data)){
        try{
            if(String(data).includes('T'))
                data = moment(data).toDate();
            else
                data = moment(data,"yyyy-MM-DD").toDate();
        }catch(err){}
    }
    if(isNaN(data)) return '-';
    if(time)
        return moment(data).format("DD/MM/yyyy HH:mm")
    else
        return moment(data).format("DD/MM/yyyy")
} 
export function TimeFormat(data){
    if(data === undefined || data === null) return '-';
    data = moment(data).toDate();
    if(isNaN(data)){
        try{
            if(String(data).includes('T'))
                data = moment(data).toDate();
            else
                data = moment(data,"yyyy-MM-DD").toDate();
        }catch(err){}
    }
    if(isNaN(data)) return '-';
    return moment(data).format("HH:mm")

} 
export function DateFormatJSON(data,time=true){
    if(data === undefined || data === null) return '-';
    if(isNaN(data)){
        try{
            if(String(data).includes('T'))
                data = moment(data).toDate();
            else
                data = moment(data,"yyyy-MM-DD").toDate();
        }catch(err){}
    }
    if(isNaN(data)) return '-';

    if(time)
        return moment(data).format("yyyy-MM-DD HH:mm:ss");
    else
    return moment(data).format("yyyy-MM-DD");

} 

export function DateThaiFormat(data,time=false){
    if(data === undefined || data === null) return '-';
    if(isNaN(data)){
        try{
            if(String(data).includes('T'))
                data = moment(data).toDate();
            else
                data = moment(data,"yyyy-MM-DD").toDate();
        }catch(err){}
    }
    if(isNaN(data)) return '-';
    if(time)
        return moment(data).format("DD/MM/")+(moment(data).year()+543)+ moment(data).format(" HH:mm")
    else
    return moment(data).format("DD/MM/")+(moment(data).year()+543)
} 
export function IsLogged(){
    if(localStorage.getItem("Authorization") === undefined) return false;
    if(localStorage.getItem("Authorization") === null) return false;
    if(localStorage.getItem("Authorization").length === 0) return false;
    return true;
}
export function KeyDownNumber(e){
    if(e.key === "Backspace" || e.key === "ArrowRight" || e.key === "ArrowLeft" || e.key === "Delete" || e.key === "Tab" || e.key ==='.') return;
    if(!isNaN(e.key)) return;
    e.preventDefault();
}
export function KeyDownMobile(e){
    if(e.key === "Backspace" || e.key === "ArrowRight" || e.key === "ArrowLeft" || e.key === "Delete" || e.key === "Tab") return;
    if(!isNaN(e.key)) return;
    e.preventDefault();
}

export function MobileFormat(data){
    if(data.length == 9){
        return data.substring(0,2)+'-'+data.substring(2,6)+'-'+data.substring(6);
    }
    if(data.length == 10){
        return data.substring(0,3)+'-'+data.substring(3,6)+'-'+data.substring(6);
    }
    return data
}
export function ThaiMonth(Month){
    let _strDate='';
    switch(Month){
        case 1:_strDate ='มกราคม';break;
        case 2:_strDate ='กุมภาพันธ์';break;
        case 3:_strDate ='มีนาคม';break;
        case 4:_strDate ='เมษายน';break;
        case 5:_strDate ='พฤษภาคม';break;
        case 6:_strDate ='มิถุนายน';break;
        case 7:_strDate ='กรกฎาคม';break;
        case 8:_strDate ='สิงหาคม';break;
        case 9:_strDate ='กันยายน';break;
        case 10:_strDate ='ตุลาคม';break;
        case 11:_strDate ='พฤษจิกายน';break;
        case 12:_strDate ='ธันวาคม';break;
    }
    return _strDate;

}
export function DateThaiMonth(date){

    let _curDate = moment(date);
    let _strDate='';
    switch(_curDate.month()){
        case 0:_strDate ='มกราคม';break;
        case 1:_strDate ='กุมภาพันธ์';break;
        case 2:_strDate ='มีนาคม';break;
        case 3:_strDate ='เมษายน';break;
        case 4:_strDate ='พฤษภาคม';break;
        case 5:_strDate ='มิถุนายน';break;
        case 6:_strDate ='กรกฎาคม';break;
        case 7:_strDate ='สิงหาคม';break;
        case 8:_strDate ='กันยายน';break;
        case 9:_strDate ='ตุลาคม';break;
        case 10:_strDate ='พฤษจิกายน';break;
        case 11:_strDate ='ธันวาคม';break;
    }

    return _strDate+' '+(_curDate.year()+543);
}

export function DateThai(date){

    let _curDate = moment(date);
    let _strDate='';
    switch(_curDate.month()){
        case 0:_strDate ='มกราคม';break;
        case 1:_strDate ='กุมภาพันธ์';break;
        case 2:_strDate ='มีนาคม';break;
        case 3:_strDate ='เมษายน';break;
        case 4:_strDate ='พฤษภาคม';break;
        case 5:_strDate ='มิถุนายน';break;
        case 6:_strDate ='กรกฎาคม';break;
        case 7:_strDate ='สิงหาคม';break;
        case 8:_strDate ='กันยายน';break;
        case 9:_strDate ='ตุลาคม';break;
        case 10:_strDate ='พฤษจิกายน';break;
        case 11:_strDate ='ธันวาคม';break;
    }

    return _curDate.format('DD')+' '+ _strDate+' '+(_curDate.year()+543);
}

export function RootURL(){
    //if(window.location.hostname === 'localhost') return 'http://localhost:5254'
    let _url = window.location.protocol+'//'+window.location.hostname;
    if(window.location.port.length > 0) _url = _url+':'+window.location.port;
    return _url;
}

export function APIURL(){
    switch(window.location.hostname){
        case "localhost": return 'http://localhost:5055'; break;
        case "horapacrm-uat.order.in.th": return 'https://horapacrm-uat-api.order.in.th'; break;
        case "horapacrm.order.in.th": return 'https://horapacrm-api.order.in.th'; break;
        default : return window.location.protocol+'//'+window.location.hostname+'/API'; break;
    }
}

export function FileURL(FileObj){
    // localStorage.getItem("Authorization")
    return APIURL()+'/File/'+localStorage.getItem("Authorization")+'/'+FileObj.FileID;
}
export function GetRole(){
    if(isNaN(Number(localStorage.getItem('Role')))) return 0; else return Number(localStorage.getItem('Role'));
}
export function CleanMobile(str){
     return str.replace('-','').replace(' ','').replace('_','').trim();
}
export function GetAuthorization(){
    const _Authorization = localStorage.getItem("Authorization");
    if(_Authorization === undefined || _Authorization === null) return null;
    return localStorage.getItem("Authorization");
}
export default{NumberFormat,MobileFormat,DateFormatJSON,DateFormat,DateThaiFormat,DateThaiMonth,DateThai,IsLogged,RootURL,GetRole,CleanMobile,APIURL,FileURL,GetAuthorization,KeyDownMobile,KeyDownNumber}